import React, { useState } from 'react';
import { IoBuild, IoCallOutline, IoMailOutline } from "react-icons/io5";

import { MdManageAccounts } from "react-icons/md";
import ship from '../images/ship.jpeg';
import worker from '../images/worker.png';
import Logo from '../images/xbayne_logo.png';
import "../index.css";

// Import Firebase modules
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCiOjhKzB-Re2bqQCcRApZUFeyI5WyMCuk",
  authDomain: "xbayne-31d65.firebaseapp.com",
  projectId: "xbayne-31d65",
  storageBucket: "xbayne-31d65.appspot.com",
  messagingSenderId: "977079728449",
  appId: "1:977079728449:web:efe0646547f46ec25489c0",
  measurementId: "G-3B1PL0GWR2"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
console.log('Firestore instance:', firestore);


const Homepage = () => {

  // 
  const [userId, setUserId] = useState('');
  const [trackingId, setTrackingId] = useState('');
  const [userData, setUserData] = useState(null);
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [goldHistory, setGoldHistory] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const fetchUserAndDetails = async () => {
    try {
      // Check if there's a document with the same userId
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        alert('Invalid UserId!');
        return;
      }
  
      const user = userDocSnapshot.data();
  
      // Check if shipment field exists
      if (!user.shipment || !user.shipment[trackingId]) {
        alert('Invalid Tracking Id!');
        return;
      }
  
      // Fetch shipment details
      const shipmentDetails = user.shipment ? Object.values(user.shipment) : [];
      setShipmentDetails(shipmentDetails);
  
      // Fetch history details
      const historyDetails = user.history ? Object.values(user.history) : [];
      const urlParams = new URLSearchParams();
      urlParams.append('user', userId);
      urlParams.append('trackingId', trackingId);
  
      navigate(`/tracking-details?${urlParams.toString()}`);

      setUserData(user);
      setGoldHistory(historyDetails); // Assuming gold history is stored in the 'history' field
      // setModalVisible(true);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      alert('Error fetching data. Please try again.');
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setUserId('');
    setTrackingId('');
    setUserData(null);
    setShipmentDetails(null);
  };

  // form data for the contact us.
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (Object.values(formData).some((value) => value.trim() === '')) {
      alert('Please fill in all the required fields.');
    } //
     else {
      // Wait for 1 second before showing the success message
      setTimeout(() => {
        // Show success message
        alert('Message sent successfully. We will contact you soon.');

        // Clear form fields
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      }, 1000);
    }
  };

  return (
    <>
      <header id="home" className="text-white header w-full h-screen bg-stone-900">
        <div className="w-screen h-screen mx-auto p-6 pt-20 flex flex-col justify-center items-center text-center">
          <div className="header-items flex flex-col justify-center items-center">
            <h1 data-aos="zoom-in-up" data-aos-easing="ease-in-out-quart" data-aos-duration="1000" data-aos-delay="800" className="text-3xl md:text-5xl text-center pb-4 ">XBAYNE PROVIDES YOU WITH</h1>
            <h1 data-aos="zoom-in-up" data-aos-easing="ease-in-out-quart" data-aos-duration="1000" data-aos-delay="800" className="text-lg md:text-lg text-center ">world class storage and logistics</h1>
            <div className="flex gap-5 mt-8">
              <a data-aos="zoom-in" data-aos-easing="ease-in" data-aos-duration="300" data-aos-delay="1200" className="mt-3 hover:bg-stone-900 btn-primary" href="#track">Track</a>
              <a data-aos="zoom-in" data-aos-easing="ease-in" data-aos-duration="300" data-aos-delay="1600" className="mt-3 hover:bg-stone-900 btn-secondary" href="#contact">Contact Us</a>
            </div>
          </div>
        </div>
      </header>

      {/* the about section */}
      <section id="about"  className="about py-20">
        <div className="container px-6 w-full mx-auto">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-5">
            <div data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="left-about relative">
              <span className="text-stone-900 sm:text-5xl md:text-4xl lg:text-6xl shadow-sm mb-10">XBAYNE GOLD DEALERSHIP, LOGISTICS AND SECURITY</span>
              <p className="text-gray-800 leading-loose sm:text-left text-sm">At XBAYNE, We have a strong modern security Valuable storage vault; Any prospective client who wishes to store gold and export, any other precious stones or metal and or jewelry could resort to the usage of our storage facility for safe keeping at a fee. At Interlogistics Shipping and Security Services, our one-of-a kind facility was designed and built to be the finest, strongest, and most secure of its kind in the world. And it is. We invite you to visit us and see for yourself. For your peace of mind, you really should let us protect your valuable assets as well as take care of your shipping, exports and logistic needs.</p>
            </div>
            <div data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="right-about">
              <img className="object-cover" src={ship} alt="nice building" />
            </div>
          </div>
        </div>
      <div id="track"></div>
      </section>
      
      {/* the track section */}
      <section id="about"  className="about py-20">
        <div className="container px-6 w-full mx-auto">
        <p data-aos="slide-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="text-4xl text-stone-800 mb-10">Track Shipment</p>
          <form onSubmit={(e) => {
            e.preventDefault();
            fetchUserAndDetails();}
          } method="POST" data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="shadow-lg p-6 bg-white border border-stone-300 rounded-lg" >
            <div className="mb-6">
              <input value={userId} onChange={(e) => setUserId(e.target.value)} required name="phone" type="text" placeholder="User Id" className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"/>
            </div>
            <div className="mb-6">
              <input value={trackingId} onChange={(e) => setTrackingId(e.target.value)} required name="phone" type="text" placeholder="Tracking Id" className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"/>
            </div>
            <div>
              <input type="submit" className="btn-primary shadown-sm w-full rounded border text-white p-3 cursor-pointer" value="Track Shipment" />
            </div>
          </form>
        </div>
      </section>

      {modalVisible && (
        <div className='border z-50 w-full h-[1200px] bg-white fixed top-0 left-0 border-black shadow-md p-2 overflow-y-scroll'>
          <img width={50} height={50} src={Logo} />
          <button className='bg-red-500 text-sm text-white my-4 me-auto absolute right-10 -top-2' onClick={closeModal}>X</button>
          {/* Content for Tab 3 (Shipment Details) */}
          {userData && (
            <div className={`mt-8 p-3 shadow-sm ${userData.latestStatus === 'green' ? 'bg-green' : 'bg-red'}`}>
              <div className={`center border relative ${userData.latestStatus === 'green' ? 'bg-green-100' : 'bg-red-100'} h-44 p-4`}>
                <span className={`w-2 ${userData.latestStatus === 'green' ? 'bg-green-700' : 'bg-red-700'} absolute left-0 top-0 h-full h-100`}></span>
                <span>
                  <p className={`font-bold text-lg ${userData.latestStatus === 'green' ? 'text-green-900' : 'text-red-900'}`}>Latest Update</p>
                  <p className={`text-sm ${userData.latestStatus === 'green' ? 'text-gray-700' : 'text-gray-800'}`}>{userData.latestMessage}</p>
                </span>
                {userData.latestStatus === 'red' && (
                  <span className='absolute -bottom-10 font-bold text-lg text-red-600'>Alert</span>
                )}
              </div>
            </div>
          )}
          {shipmentDetails && (
            <div className='p-3'>
              {Object.entries(shipmentDetails).reverse().map(([trackingId, shipmentData], shipmentIndex) => (
                <div key={shipmentIndex} className='grid grid-cols-1 py-4 text-center'>
                  {Object.entries(shipmentData).reverse().map(([shipmentKey, shipmentItem], itemIndex) => (
                    <div
                      key={itemIndex}
                      className={`grid grid-cols-2 my-2 h-32 relative border ${
                        itemIndex === 0 && shipmentItem.status === 'Delivered'
                          ? 'bg-green' // Apply green background for the first child with Delivered status
                          : ''
                      }`}
                    >
                      <span className='w-2 absolute h-32 rounded-md left-0 top-0 bg-black'></span>
                      <span className='w-4 absolute h-4 rounded-full border-4 border-white -left-1 top-0 bg-black'></span>
                      <span className='w-4 absolute h-4 rounded-full border-4 border-white -left-1 -bottom-2 bg-black'></span>
                      <div className='text-left py-2'>
                        <p className={`text-[20px] ml-5 text-sm font-bold ${
                          itemIndex === 0 && shipmentItem.status === 'Delivered'
                            ? 'text-green-500' // Apply green text color for the first child with Delivered status
                            : 'text-blue-900' // Maintain the default text color
                        }`}>
                          {shipmentItem.status || 'status'}
                        </p>
                        <p className='text-[16px] ml-5 mt-4 text-sm text-stone-800'>{shipmentItem.message || 'message'}</p>
                        <p className='text-[16px] ml-5 text-sm text-stone-800'>{shipmentItem.location || 'message'}</p>
                        <p className='text-[16px] ml-5 text-sm text-stone-800'>{shipmentItem.date || 'date'}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}

        </div>
      )}

      {/* services */}
      <section id="services" className="services py-20">
        <div className="container mx-auto px-6">
          <p data-aos="slide-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="text-4xl text-stone-800 mb-10">Our Services</p>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="0" className="hover:shadow-lg hover:bg-blue-100  shadow-md rounded-md p-4"> 
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>Vault Rental</h3>
                <MdManageAccounts className="text-2xl"/>

              </div>
              <p className="text-stone-600 text-sm leading-loose">
              We secure and safeguard your valuable mineral and assets with strict surveillance and adequate real time monitoring.We know that you care about your belongings and want the best self storage value, security, and protection. We are confident that our self storage locations will meet your high expectation.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="150" className="hover:shadow-lg hover:bg-blue-100  shadow-md rounded-md p-4">
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>Packaging and Storage</h3>
                <MdManageAccounts className="text-2xl"/>

              </div>
              <p className="text-stone-600 text-sm leading-loose">
              We engage in warehousing and storage facilities for general mechandise. We handle cargos and store them under maximum supervision and security in our vast warehouse. With our own logistic centers spread throughout the globe, which helps us to provide safe, reliable, economical and customized logistic solutions to our clients and partners.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="450" className="hover:shadow-lg hover:bg-blue-100  shadow-md rounded-md p-4">
              <div  className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>Contract Logistics</h3>
                <MdManageAccounts className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              We provide logistic services in the nation, whether it is freight transportation, supply chain solutions, warehousing and distribution, customer resource area services, customs, security and insurance, temperature controlled logistics, industry sector solutions, brokerage, or lead logistic based solutions.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="450" className="hover:shadow-lg hover:bg-blue-100  shadow-md rounded-md p-4">
              <div  className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>Air Freight</h3>
                <MdManageAccounts className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              With our Air freight forwarding services, along with other ancillary services, we provide tailor made logistic solutions to our clients, which we design as per their requirements and business ecosystem to help cut their cost, delivery time, while focusing on end user satisfaction.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="450" className="hover:shadow-lg hover:bg-blue-100  shadow-md rounded-md p-4">
              <div  className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>OCEAN FREIGHT</h3>
                <MdManageAccounts className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              We have experts who are always available to help you with any queries you might have, or if you want to consult in length your sea freight requirements. We would study your requirements and provide you with a quote that would not only suit your budget, but would also save you considerable amount of money in the long term.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="450" className="hover:shadow-lg hover:bg-blue-100  shadow-md rounded-md p-4">
              <div  className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>Road Freight</h3>
                <MdManageAccounts className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              Transport your things with our super fast moving Vans. We ensure that our fleet of trucks are always well maintained, and have the best and the most updated fleet of trucks in service to ensure that it is capable of delivering high value, and oversized deliverables, efficiently and effectively, without causing any kind of loss, which is generally the case seen with many outsourced trucking service providers.
              </p>
            </a>
          </div>
        </div>
      </section>

      {/* the about section */}
      <section id="about"  className="about py-20">
        <div className="container px-6 w-full mx-auto">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-5">
            <div data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="right-about">
              <img className="object-cover" src={worker} alt="worker" />
            </div>
            <div data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="left-about relative">
              <span className="text-stone-900 sm:text-5xl md:text-4xl lg:text-6xl shadow-sm mb-10">Industry Sector Coverages</span>
              <ul className='mt-12'>
                <li className='text-xl py-4'><span>- </span> Chemical Goods</li>
                <li className='text-xl py-4'><span>- </span> Minerals(Gold, diamond)</li>
                <li className='text-xl py-4'><span>- </span> Jewelry</li>
                <li className='text-xl py-4'><span>- </span> Energy Oil, and Gas</li>
                <li className='text-xl py-4'><span>- </span> Retail Goods</li>
              </ul>
            </div>
            
          </div>
        </div>
      <div id="track"></div>
      </section>

      {/* why choose us */}
      <section id="projects" className="Projects bg-stone-900 py-20">
        <div className="container mx-auto px-6">
          <p data-aos="slide-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="text-4xl text-gray-400 mb-10">Why Choose us</p>

          <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="0" className="hover:shadow-lg hover:bg-blue-100 bg-white  shadow-md rounded-md p-4"> 
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>CUSTOMER SATISFICATION TOOLS</h3>
                <IoBuild className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              Interlogistics Shipping and Security maintains strict privacy policies of each account ensuring a complete confidentiality.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="0" className="hover:shadow-lg hover:bg-blue-100 bg-white  shadow-md rounded-md p-4"> 
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>MANAGEMENT & REPORTING</h3>
                <IoBuild className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              We assist private individuals, partnerships, associations or corporations to meet importers and exporters Federal requirements governing imports and exports.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="0" className="hover:shadow-lg hover:bg-blue-100 bg-white  shadow-md rounded-md p-4"> 
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>safe Storage</h3>
                <IoBuild className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              We secure and safeguard your valuable mineral and assets with strict surveillance and adequate real time monitoring.We know that you care about your belongings and want the best self storage value, security, and protection. We are confident that our self storage locations will meet your high expectation
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="0" className="hover:shadow-lg hover:bg-blue-100 bg-white  shadow-md rounded-md p-4"> 
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>Insurance Protection</h3>
                <IoBuild className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              Your assets are insured with Import and Export logistics independently against physical loss, damage or theft.
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="0" className="hover:shadow-lg hover:bg-blue-100 bg-white  shadow-md rounded-md p-4"> 
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>Real Time Monitoring</h3>
                <IoBuild className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              With our real-time tracking algorithms you can monitor valuables deposited in our vault and track them in real time when in transit
              </p>
            </a>
            <a href="#a" data-aos="zoom-in-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="0" className="hover:shadow-lg hover:bg-blue-100 bg-white  shadow-md rounded-md p-4"> 
              <div className="flex flex-col gap-3 pb-3 justify-center items-center text-center">
                <h3 className='text-lg'>24 / 7 SERVICE</h3>
                <IoBuild className="text-2xl"/>
              </div>
              <p className="text-stone-600 text-sm leading-loose">
              We have professional work force that ensures all your complaints and concerns are addressed all the time, no matter which time of the day.
              </p>
            </a>
            
          </div>
        </div>
        <div id="contact"></div>
      </section>

      {/* Contact sections */}
      <section id="contact" className="contact py-20">
        <div className="container mx-auto px-6">
          <p data-aos="slide-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="text-4xl text-gray-400 mb-10">Reach out to us</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="contact-left">
              <p className="leading-loose text-gray-800">Are you interested to know more about how we work? <br /> Do you have a business proposal? <br />Would you like to make a purchase from us? <br /> We would love to hear from you.</p>
              <div className=" pt-5 flex flex-col justify-start items-start">
                <div data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="500" className="py-2 my-4 flex gap-5 location">
                  <div>
                    <IoMailOutline className="text-3xl text-blue-400"/>
                  </div>
                  <div>
                    <h3>Address</h3>
                    <p className="text-gray-600 py-2"> Bez Adresa Mira St Bashkortostan, Ufa</p>
                    <p className="text-gray-600 py-2">mail: gold.xbaynegroupofcompanies@gmail.com.</p>
                  </div>
                </div>
                <div data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-delay="300" className="py-2 my-4 flex gap-5 location">
                  <div>
                    <IoCallOutline className="text-3xl text-blue-400"/>
                  </div>
                  <div>
                    <h3>Phone Number</h3>
                    <p className="text-gray-600 py-2">+7(347)384-9247 </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
            
            <form
                onSubmit={handleSubmit}
                data-aos="fade-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                className="shadow-lg p-6 bg-white border border-stone-300 rounded-lg"
              >
                <div className="mb-6">
                  <input
                    required
                    name="name"
                    type="text"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                  />
                </div>
                <div className="mb-6">
                  <input
                    required
                    name="email"
                    type="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                  />
                </div>
                <div className="mb-6">
                  <input
                    required
                    name="phone"
                    type="number"
                    placeholder="Your Phone(optional)"
                    value={formData.phone}
                    onChange={handleChange}
                    className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                  />
                </div>
                <div className="mb-6">
                  <textarea
                    required
                    name="message"
                    rows="6"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    className="text-body-color border-[f0f0f0] focus:border-primary w-full resize-none rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                  ></textarea>
                </div>
                <div>
                  <input
                    type="submit"
                    className="text-white btn-primary shadown-sm w-full rounded border p-3 cursor-pointer"
                    value="Send Message"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      
    </>
  )
}

export default Homepage