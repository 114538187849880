import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import 'firebase/firestore';
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { auth, firestore } from "../firebase-config";
import profileImage from '../images/profile-image-37767100237hha82js.jpg';
import Logo from '../images/xbayne_logo.png';
import './login.css';

function Dashboard() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [user, setUser] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [userId, setUserId] = useState('');
//   const [trackingId, setTrackingId] = useState('');
  const [userData, setUserData] = useState(null);
//   const [shipmentDetails, setShipmentDetails] = useState(null);
  const [goldHistory, setGoldHistory] = useState([]);
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [trackingId, setTrackingId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth,async (currentUser) => {
        setUser(currentUser);

      if(currentUser){
        try {
            // Check if there's a document with the same email
            const querySnapshot = await getDocs(
              query(collection(firestore, 'users'), where('email', '==',currentUser.email ))
            );
        
            if (querySnapshot.empty) {
              alert('User not found!');
              return;
            }
        
            // Assuming there's only one document with the given email
            const userDocSnapshot = querySnapshot.docs[0];
            const user = userDocSnapshot.data();
            const historyDetails = user.history ? Object.values(user.history) : [];
        
            setUserId(userDocSnapshot.id);
            setUserData(user);
            setGoldHistory(historyDetails);
        
          } catch (error) {
            console.error('Error fetching data:', error.message);
            alert('Error fetching data. Please try again.');
          }
      }
    });
  
    return () => unsubscribe();
  }, []);

  const fetchUserAndDetails = async () => {
    try {
      // Check if there's a document with the same userId
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        alert('Invalid UserId!');
        return;
      }
  
      const user = userDocSnapshot.data();
  
      // Check if shipment field exists
      if (!user.shipment || !user.shipment[trackingId]) {
        alert('Invalid Tracking Id!');
        return;
      }
  
      // Fetch shipment details
      const shipmentDetails = user.shipment ? Object.values(user.shipment) : [];
      setShipmentDetails(shipmentDetails);
  
      // Fetch history details
      const historyDetails = user.history ? Object.values(user.history) : [];
  
      // setUserData(user);
      // setGoldHistory(historyDetails); // Assuming gold history is stored in the 'history' field
      setModalVisible(true);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      alert('Error fetching data. Please try again.');
    }
  };


  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      console.log(user);

      try {
    
        // Check if there's a document with the same email
        const querySnapshot = await getDocs(
          query(collection(firestore, 'users'), where('email', '==', loginEmail))
        );
    
        if (querySnapshot.empty) {
          alert('User not found!');
          return;
        }
    
        // Assuming there's only one document with the given email
        const userDocSnapshot = querySnapshot.docs[0];
        const user = userDocSnapshot.data();
        const historyDetails = user.history ? Object.values(user.history) : [];
    
        setUserId(userDocSnapshot.id);
        setUserData(user);
        setGoldHistory(historyDetails);
      } //
       catch (error) {
        console.error('Error fetching data:', error.message);
        alert('Error fetching data. Please try again.');
      }

    } catch (error) {
      console.log(error.message);
      alert('Invalid Login credentials, Please Try Again');
      await signOut();
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const logout = async () => {
    try {
      await signOut(auth);
      alert('Logged out');
    } // 
     catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  // Conditionally render the login form or dashboard section
  return (
    <div>
      {user ? (
        // Dashboard Section
        <section className="w-full">
            <nav
                data-aos="slide-down"
                data-aos-easing="ease-in"
                data-aos-duration="1000"
                className="w-full bg-white p-6 fixed top-0 z-50 flex justify-center items-center shadow-md text-stone-300"
                >
                    {/* large screen navbar */}
                    <div className="w-full px-6 container hidden md:flex justify-center items-center gap-6 text-md">
                        <Link to="/dashboard" className="cursor-pointer text-md text-center flex flex-col justify-center items-center">
                            <img src={Logo} width={50} alt="xbayne" />
                            <p className="text-black">XBAYNE</p>
                        </Link>
                        <div className="gap-5 flex ml-auto text-stone-900">
                            <button className="block text-sm bg-red-600 ml-auto cursor-pointer" onClick={logout}>Sign Out</button>
                        </div>
                    </div>

                    {/* mobile screen navbar */}
                    <div className="w-full md:hidden container flex justify-center items-center text-lg">
                        <Link to="/" className="cursor-pointer text-md text-center flex flex-col justify-center items-center">
                        <img src={Logo} width={50} alt="Xbayne" />
                        <p className="text-black">XBAYNE</p>
                        </Link>

                        <button className="block text-sm bg-red-600 md:hidden ml-auto cursor-pointer" onClick={logout}>Sign Out</button>

                        {/* navbar toggler menu */}
                        {/* <div onClick={() => setIsActive(!isActive)} className="block md:hidden ml-auto cursor-pointer">
                        <IoMenu className="text-2xl" />
                        </div> */}
                        {isActive && (
                        <ul
                            onClick={() => setIsActive(false)}
                            className="mobile-nav-list rounded-md absolute bg-white text-stone-900 p-6 top-20 left-0 w-full shadow-md"
                        >
                        </ul>
                        )}
                    </div>
                </nav>
                <main className="mt-8 pt-20">
                    <div className="container w-full mx-auto px-6">
                      {userData?(
                        <h2>Welcome, {userData.name}</h2>
                      ):
                      <h2>Welcome, </h2>

                      }
                        <Tabs className={'mt-6 -ml-4'}>
                            <TabList className={'flex px-4 text-white '}>
                            <Tab className={'bg-black mr-4 p-4 cursor-pointer hover:text-blue-500 hover:bg-stone-700'}>Profile</Tab>
                            <Tab className={'bg-black mr-4 p-4 cursor-pointer hover:text-blue-500 hover:bg-stone-700'}>Storage</Tab>
                            <Tab className={'bg-black mr-4 p-4 cursor-pointer hover:text-blue-500 hover:bg-stone-700'}>Tracking</Tab>
                            </TabList>

                            <TabPanel>
                            {/* Content for Tab 1 */}
                            {/* For example, you can display user information here */}
                            {userData? (
                                <div className='p-3'>
                                <h2 className='text-2xl'>User Profile</h2>
                                {/* <p className='py-5'>User ID: {userId}</p> */}
                                <div className='grid grid-cols-1 border shadow-lg rounded-md py-4 text-center'>
                                    <ul>
                                    <img width={150} height={100} className="shadow-lg object-cover mx-auto  rounded-full" src={profileImage} alt="nice building" />

                                    <p className='text-blue-500 font-extrabold'>Personal Information</p>
                                    <li className='my-2'> <span className='font-bold'>Name:</span> <p className='text-sm text-gray-600'>{userData.name}</p> </li>
                                    <li className='my-2'> <span className='font-bold'>Date of Birth:</span> <p className='text-sm text-gray-600'>{userData.dob}</p> </li>
                                    <li className='my-2'> <span className='font-bold'>Gender:</span> <p className='text-sm text-gray-600'>{userData.gender}</p> </li>
                                    <li className='my-2'> <span className='font-bold'>Marital Status:</span> <p className='text-sm text-gray-600'>{userData.status}</p> </li>
                                    <li className='my-2'> <span className='font-bold'>Nationality:</span> <p className='text-sm text-gray-600'>{userData.nationality}</p> </li>
                                    </ul>
                                    <ul className='mt-5'>
                                    <p className='text-blue-500 font-extrabold'>Contact Information</p>
                                    <li className='my-2'> <span className='font-bold'>Email:</span> <p className='text-sm text-gray-600'>{userData.email}</p> </li>
                                    <li className='my-2'> <span className='font-bold'>Phone:</span> <p className='text-sm text-gray-600'>{userData.phone}</p> </li>
                                    <li className='my-2'> <span className='font-bold'>Address:</span> <p className='text-sm text-gray-600'>{userData.address}</p> </li>
                                    </ul>
                                    <ul className='mt-5'>
                                    <p className='text-blue-500 font-extrabold'>Professional Information</p>
                                    <li className='my-2'> <span className='font-bold'>Job Title:</span> <p className='text-sm text-gray-600'>{userData.job}</p> </li>
                                    <li className='my-2'> <span className='font-bold'>Institution:</span> <p className='text-sm text-gray-600'>{userData.company}</p> </li>
                                    </ul>
                                </div>
                                {/* Display other user information */}
                                </div>
                            ):<div className="p-24">Loading User Profile, Please wait</div>}
                            </TabPanel>
                            
                            <TabPanel>
                            {/* Content for Tab 2 */}
                            {goldHistory && (
                                <div className='p-3 grid-cols-4'>
                                <h2 className='text-2xl'>Gold Deposited</h2>
                                  {goldHistory.map((historyItem, index) => (
                                      <div key={index} className='grid md:grid-cols-1 border shadow-lg rounded-md py-4 text-center'>
                                        <ul>
                                            <li className='my-2'> <span className='font-bold'>Date:</span> <p className='text-sm text-gray-600'>{historyItem.date}</p> </li>
                                            <li className='my-2'> <span className='font-bold'>Item:</span> <p className='text-sm text-gray-600'>{historyItem.item}</p> </li>
                                            <li className='my-2'> <span className='font-bold'>Quantity:</span> <p className='text-sm text-gray-600'>{historyItem.quantity}</p> </li>
                                            <li className='my-2'> <span className='font-bold'>Deposit Value:</span> <p className='text-sm text-gray-600'>{historyItem.value}</p> </li>
                                            <li className='my-2'> <span className='font-bold'>Weight:</span> <p className='text-sm text-gray-600'>{historyItem.weight}</p> </li>
                                            {/* Add more fields as needed */}
                                        </ul>
                                      </div>
                                  ))}
                                </div>
                            )}
                            </TabPanel>
                            <TabPanel>
                                {/* the track section */}
                              <section id="about"  className="about py-20">
                                <div className="container px-6 w-full mx-auto">
                                <p data-aos="slide-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="text-4xl text-stone-800 mb-10">Enter Tracking Id</p>
                                  <form onSubmit={(e) => {
                                    e.preventDefault();
                                    fetchUserAndDetails();}
                                  } method="POST" data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className="shadow-lg p-6 bg-white border border-stone-300 rounded-lg" >
                                    <div className="mb-6">
                                      <input value={trackingId} onChange={(e) => setTrackingId(e.target.value)} required name="phone" type="text" placeholder="Tracking Id" className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"/>
                                    </div>
                                    <div>
                                      <input type="submit" className="btn-primary shadown-sm w-full rounded border text-white p-3 cursor-pointer" value="Track Shipment" />
                                    </div>
                                  </form>
                                </div>
                              </section>
                              {modalVisible && (
                                <div className='border z-50 w-full h-full bg-white fixed top-0 left-0 text-black border-black shadow-md p-2 overflow-y-scroll'>
                                  <div>
                                    <img width={50} height={50} src={Logo} />
                                    <h2>XBAYNE</h2>
                                  </div>
                                  <button className='bg-red-500 text-sm text-white my-4 me-auto absolute right-10 -top-2' onClick={closeModal}>X</button>
                                  {/* Content for Tab 3 (Shipment Details) */}
                                  {userData && (
                                    <div className={`mt-8 p-3 shadow-sm ${userData.latestStatus === 'green' ? 'bg-green' : 'bg-red'}`}>
                                      <div className={`center border relative ${userData.latestStatus === 'green' ? 'bg-green-100' : 'bg-red-100'} h-44 p-4`}>
                                        <span className={`w-2 ${userData.latestStatus === 'green' ? 'bg-green-700' : 'bg-red-700'} absolute left-0 top-0 h-full h-100`}></span>
                                        <span>
                                          <p className={`font-bold text-lg ${userData.latestStatus === 'green' ? 'text-green-900' : 'text-red-900'}`}>Latest Update</p>
                                          <p className={`text-sm ${userData.latestStatus === 'green' ? 'text-gray-700' : 'text-gray-800'}`}>{userData.latestMessage}</p>
                                        </span>
                                        {userData.latestStatus === 'red' && (
                                          <span className='absolute -bottom-10 font-bold text-lg text-red-600'>Alert</span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {shipmentDetails && (
                                    <div className='p-3'>
                                      {Object.entries(shipmentDetails).reverse().map(([trackingId, shipmentData], shipmentIndex) => (
                                        <div key={shipmentIndex} className='grid grid-cols-1 py-4 text-center'>
                                          {Object.entries(shipmentData).reverse().map(([shipmentKey, shipmentItem], itemIndex) => (
                                            <div
                                              key={itemIndex}
                                              className={`grid grid-cols-2 my-2 h-32 relative border ${
                                                itemIndex === 0 && shipmentItem.status === 'Delivered'
                                                  ? 'bg-green' // Apply green background for the first child with Delivered status
                                                  : ''
                                              }`}
                                            >
                                              <span className='w-2 absolute h-32 rounded-md left-0 top-0 bg-black'></span>
                                              <span className='w-4 absolute h-4 rounded-full border-4 border-white -left-1 top-0 bg-black'></span>
                                              <span className='w-4 absolute h-4 rounded-full border-4 border-white -left-1 -bottom-2 bg-black'></span>
                                              <div className='text-left py-2'>
                                                <p className={`text-[20px] ml-5 text-sm font-bold ${
                                                  itemIndex === 0 && shipmentItem.status === 'Delivered'
                                                    ? 'text-green-500' // Apply green text color for the first child with Delivered status
                                                    : 'text-blue-900' // Maintain the default text color
                                                }`}>
                                                  {shipmentItem.status || 'status'}
                                                </p>
                                                <p className='text-[16px] ml-5 mt-4 text-sm text-stone-800'>{shipmentItem.message || 'message'}</p>
                                                <p className='text-[16px] ml-5 text-sm text-stone-800'>{shipmentItem.location || 'message'}</p>
                                                <p className='text-[16px] ml-5 text-sm text-stone-800'>{shipmentItem.date || 'date'}</p>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                </div>
                              )}
                            </TabPanel>

                            
                        </Tabs>
                    </div>
                    
                </main>
        </section>
      ) : (
        // Login Form
        <div className="app-container">
            <div className="form-container">
            <h3>Login</h3>
            <input
                required
                type="email"
                placeholder="Enter email..."
                onChange={(event) => {
                setLoginEmail(event.target.value);
                }}
            />
            <input
                required
                type="password"
                placeholder="Enter Password..."
                onChange={(event) => {
                setLoginPassword(event.target.value);
                }}
            />
            <button onClick={login}>Login</button>
            </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;




// <TabPanel>
//                             {/* Content for Tab 3 (Shipment Details) */}
//                             {shipmentDetails && (
//                                 <div className='p-3'>
//                                 <h2 className='text-2xl'>Shipment Details</h2>
//                                 {Object.entries(shipmentDetails).map(([trackingId, shipmentData], shipmentIndex) => (
//                                     <div key={shipmentIndex} className='grid grid-cols-1 border shadow-lg rounded-md py-4 text-center'>
//                                     {Object.entries(shipmentData).map(([shipmentKey, shipmentItem], itemIndex) => (
//                                         <div
//                                         key={itemIndex}
//                                         className={`grid grid-cols-2 my-2 border m-2 ${
//                                             shipmentItem.status === 'successful'
//                                             ? 'bg-green-200'
//                                             : shipmentItem.status === 'pending'
//                                             ? 'bg-yellow-200'
//                                             : 'bg-red-400'
//                                         }`}
//                                         >
//                                         <span className='font-bold text-left pl-2'>Date:</span>
//                                         <p className='text-sm text-right pr-2 text-gray-600'>{shipmentItem.date}</p>

//                                         <span className='font-bold text-left pl-2'>Location:</span>
//                                         <p className='text-sm text-right pr-2 text-gray-600'>{shipmentItem.location}</p>

//                                         <span className='font-bold text-left pl-2'>Activity:</span>
//                                         <p className='text-sm text-right pr-2 text-gray-600'>{shipmentItem.message}<span className='text-red-900 bg-white'>{shipmentItem.amountToPay}</span></p>

//                                         <span className='font-bold text-left pl-2'>Status:</span>
//                                         <p className='text-sm text-right pr-2 text-gray-600'>{shipmentItem.status}</p>
//                                         </div>
//                                     ))}
//                                     </div>
//                                 ))}
//                                 </div>
//                             )}
//                             </TabPanel>