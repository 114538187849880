import React from 'react'
import { Link } from 'react-router-dom'


const Error = () => {
  return (
    <div className="flex flex-col justify-center items-center mx-auto container p-6 w-screen h-screen">
        <h2 className="text-xl">sorry! Looks like the page you are looking for doesn't exist</h2>
        <br />
        <Link className="text-blue-400 hover:text-blue-800" to="/">Back To Homepage</Link>
    </div>
  )
}

export default Error