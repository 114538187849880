import React from 'react'

import { createRoot } from "react-dom/client"
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import Preloader from "./Components/Preloader"
import './index.css'
import Dashboard from './Pages/dashboard'
import Error from "./Pages/Error"
import Homepage from "./Pages/Homepage"
import TrackingDetails from './Pages/TrackingDetails'

const SharedLayout = React.lazy( () =>{
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./Components/SharedLayout')), 1500)
  })
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={ 
        <React.Suspense fallback={ <Preloader />}>
          <SharedLayout />
        </React.Suspense>}>
        <Route index element={<Homepage />} />
        <Route element={<Homepage />} />
      </Route>
      <Route path="*" element={<Error />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="tracking-details" element={<TrackingDetails />} />
    </>
  )
)

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
