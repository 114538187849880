import React from 'react'
import Logo from "../images/xbayne_logo.png"

const Preloader = () => {
  return (
    <div className="w-screen text-center h-screen flex flex-col justify-center items-center">
        <img src={ Logo } width={50} alt="Xbayne"/>
        <p className="pt-1">XBAYNE</p>
    </div>
  )
}

export default Preloader