import { initializeApp } from "firebase/app";
import { getAuth, } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCiOjhKzB-Re2bqQCcRApZUFeyI5WyMCuk",
    authDomain: "xbayne-31d65.firebaseapp.com",
    projectId: "xbayne-31d65",
    storageBucket: "xbayne-31d65.appspot.com",
    messagingSenderId: "977079728449",
    appId: "1:977079728449:web:efe0646547f46ec25489c0",
    measurementId: "G-3B1PL0GWR2"
  };


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);