import React, { useEffect, useState } from 'react';

import Logo from '../images/xbayne_logo.png';
import "../index.css";

// Import Firebase modules
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCiOjhKzB-Re2bqQCcRApZUFeyI5WyMCuk",
  authDomain: "xbayne-31d65.firebaseapp.com",
  projectId: "xbayne-31d65",
  storageBucket: "xbayne-31d65.appspot.com",
  messagingSenderId: "977079728449",
  appId: "1:977079728449:web:efe0646547f46ec25489c0",
  measurementId: "G-3B1PL0GWR2"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
console.log('Firestore instance:', firestore);  console.log('Firestore instance:', firestore);
  
export default function TrackingDetails() {
  
  const [userId, setUserId] = useState('');
  const [shipmentDetails, setShipmentDetails] = useState(null);

  const [userData, setUserData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // Function to fetch user data and details based on URL parameters
    const fetchUserAndDetails = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const userId = urlParams.get('user');
        const trackingId = urlParams.get('trackingId');
        console.log(userId, trackingId)

        // Check if there's a document with the same userId
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnapshot = await getDoc(userDocRef);
    
        if (!userDocSnapshot.exists()) {
            alert('Invalid UserId!');
            return;
        }
    
        const user = userDocSnapshot.data();
    
        // Check if shipment field exists
        if (!user.shipment || !user.shipment[trackingId]) {
            alert('Invalid Tracking Id!');
            return;
        }

        const shipmentDetails = user.shipment ? Object.values(user.shipment) : [];
      setShipmentDetails(shipmentDetails);

        // Set the fetched data to state
        setUserData(user);
        console.log(user)


        // Show the modal
        setModalVisible(true);
      } catch (error) {
        console.error('Error fetching data:', error.message);
        alert('Error fetching data. Please try again.');
      }
    };

    // Call the fetchUserAndDetails function when the component mounts
    fetchUserAndDetails();
  }, []); 
  const navigate = useNavigate();


  const closeModal = () => {
    setModalVisible(false);
    setUserId('');
    setUserData(null);
    setShipmentDetails(null);

    navigate(`../`);

  };
    return(
        <section className='overflow-y-scroll h-full'>
            {modalVisible && (
                <div className='border z-50 w-full h-screen bg-white fixed top-0 left-0 border-black shadow-md p-2 overflow-y-scroll'>
                    <div>
                      <img width={50} height={50} src={Logo} />
                      <h2 className='text-black'>XBAYNE</h2>
                    </div>
                    <button className='bg-red-500 text-sm text-white my-4 me-auto absolute right-10 -top-2' onClick={closeModal}>X</button>
                {/* Content for Tab 3 (Shipment Details) */}
                {userData && (
                    <div className={`mt-8 p-3 shadow-sm ${userData.latestStatus === 'green' ? 'bg-green' : 'bg-red'}`}>
                    <div className={`center border relative ${userData.latestStatus === 'green' ? 'bg-green-100' : 'bg-red-100'} h-44 p-4`}>
                        <span className={`w-2 ${userData.latestStatus === 'green' ? 'bg-green-700' : 'bg-red-700'} absolute left-0 top-0 h-full h-100`}></span>
                        <span>
                        <p className={`font-bold text-lg ${userData.latestStatus === 'green' ? 'text-green-900' : 'text-red-900'}`}>Latest Update</p>
                        <p className={`text-sm ${userData.latestStatus === 'green' ? 'text-gray-700' : 'text-gray-800'}`}>{userData.latestMessage}</p>
                        </span>
                        {userData.latestStatus === 'red' && (
                        <span className='absolute -bottom-10 font-bold text-lg text-red-600'>Alert</span>
                        )}
                    </div>
                    </div>
                )}
                {shipmentDetails && (
                    <div className='p-3'>
                    {Object.entries(shipmentDetails).reverse().map(([trackingId, shipmentData], shipmentIndex) => (
                        <div key={shipmentIndex} className='grid grid-cols-1 py-4 text-center'>
                        {Object.entries(shipmentData).reverse().map(([shipmentKey, shipmentItem], itemIndex) => (
                            <div
                            key={itemIndex}
                            className={`grid grid-cols-2 my-2 h-32 relative border ${
                                itemIndex === 0 && shipmentItem.status === 'Delivered'
                                ? 'bg-green'
                                : ''
                            }`}
                            >
                            <span className='w-2 absolute h-32 rounded-md left-0 top-0 bg-black'></span>
                            <span className='w-4 absolute h-4 rounded-full border-4 border-white -left-1 top-0 bg-black'></span>
                            <span className='w-4 absolute h-4 rounded-full border-4 border-white -left-1 -bottom-2 bg-black'></span>
                            <div className='text-left py-2'>
                                <p className={`text-[20px] ml-5 text-sm font-bold ${
                                itemIndex === 0 && shipmentItem.status === 'Delivered'
                                    ? 'text-green-500' // Apply green text color for the first child with Delivered status
                                    : 'text-blue-900' // Maintain the default text color
                                }`}>
                                {shipmentItem.status || 'status'}
                                </p>
                                <p className='text-[16px] ml-5 mt-4 text-sm text-stone-800'>{shipmentItem.message || 'message'}</p>
                                <p className='text-[16px] ml-5 text-sm text-stone-800'>{shipmentItem.location || 'message'}</p>
                                <p className='text-[16px] ml-5 text-sm text-stone-800'>{shipmentItem.date || 'date'}</p>
                            </div>
                            </div>
                        ))}
                        </div>
                    ))}
                    </div>
                )}

                </div>
            )}
        </section>
    )
}